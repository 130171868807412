
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';

// Hardcoded data
const PATHWAYS = [
  '23andMe',
  'Airbnb',
  'American Red Cross',
  'Bird',
  'Blue Origin',
  'Duolingo',
  'Gucci',
  'IKEA',
  'Impossible Foods',
  'Instagram',
  'Intel Corporation',
  'Khan Academy',
  'Lyft',
  'Moderna',
  'Netflix',
  'Nike',
  'Peloton Interactive',
  'Snap Inc.',
  'SpaceX',
  'Square',
  'Tesla',
  'Turner Construction Company',
  'Walt Disney World',
  'Zoom'
];
const PROBLEMS = [
  {
    employer: '23andMe',
    problem:
      'Think of innovative ideas to gamify the 23andMe platform to encourage customers to explore genetic data & spark curiosity in testing process. Current solutions lack engagement',
    website: 'http://www.23andme.com',
    pathway: '23andMe'
  },
  {
    employer: 'Airbnb',
    problem:
      'Think of ways to build a virtual community on Airbnb, allowing guests and hosts to connect, share travel tips & experiences, creating a sense of belonging. Current solutions like chat groups are limited and lack inclusivity',
    website: 'http://www.airbnb.com',
    pathway: 'Airbnb'
  },
  {
    employer: 'American Red Cross',
    problem:
      'Think of creative ways to train American Red Cross volunteers in mental health first aid & emotional support to better help those in crisis when they need it',
    website: 'http://www.redcross.org',
    pathway: 'American Red Cross'
  },
  {
    employer: 'Bird',
    problem:
      'Think of ways to create designated parking zones for Bird vehicles with local communities to improve convenience and safety for riders. Current solutions are limited and not tailored to specific areas',
    website: 'http://www.bird.co',
    pathway: 'Bird'
  },
  {
    employer: 'BLUE ORIGIN',
    problem:
      'Think of creative solutions for sustainable space travel. Showcase potential impact on future of space. Current solutions limited & costly, need fresh ideas from young minds to advance space exploration',
    website: 'http://www.blueorigin.com',
    pathway: 'BLUE ORIGIN'
  },
  {
    employer: 'Duolingo',
    problem:
      'Think of new ways to connect language learners with native speakers on Duolingo to enhance learning & connection. Current solutions lack interaction and community support',
    website: 'http://www.duolingo.com',
    pathway: 'Duolingo'
  },
  {
    employer: 'Gucci',
    problem:
      'Think of a tool for customizing Gucci luxury products with monograms or customer designs. Make shopping bespoke, exclusive and personalized',
    website: 'http://www.gucci.com',
    pathway: 'Gucci'
  },
  {
    employer: 'IKEA',
    problem:
      'Think outside the box! Think of partnerships with artists & designers to make exclusive furniture collections for art lovers ',
    website: 'http://www.ikea.com',
    pathway: 'IKEA'
  },
  {
    employer: 'Impossible Foods',
    problem:
      'Think of tasty ways to promote Impossible Foods plant-based products through interactive cooking classes with chefs. Current solutions lack creativity.',
    website: 'http://www.impossiblefoods.com',
    pathway: 'Impossible Foods'
  },
  {
    employer: 'Instagram',
    problem:
      'Think of ways to enhance engagement and growth on Instagram by creating a virtual event feature for businesses and influencers to host interactive live sessions with their followers',
    website: 'http://www.instagram.com',
    pathway: 'Instagram'
  },
  {
    employer: 'Intel Corporation',
    problem:
      'Think of new ways to inspire and train students in semiconductor chip design & manufacturing for Intel. Current methods lack hands-on experience and opportunities',
    website: 'http://www.intel.com',
    pathway: 'Intel Corporation'
  },
  {
    employer: 'Khan Academy',
    problem:
      'Think of creative virtual study groups/workshops led by tutors for students on Khan Academy to improve learning outcomes for all levels',
    website: 'http://www.khanacademy.org',
    pathway: 'Khan Academy'
  },
  {
    employer: 'Lyft',
    problem:
      'Think of ways to simplify scheduling recurring rides for daily commutes or regular appointments on the Lyft mobile app to provide a convenient & reliable transportation solution',
    website: 'http://www.lyft.com',
    pathway: 'Lyft'
  },
  {
    employer: 'Moderna',
    problem:
      'Think of interactive learning experiences to enhance the public’s understanding of Moderna’s mRNA technology. Current methods lack engagement, depth, and clarity',
    website: 'http://www.modernatx.com',
    pathway: 'Moderna'
  },
  {
    employer: 'Netflix',
    problem:
      'Think of new ways to gamify Netflix, rewarding loyal watchers, subscribers while fostering community & loyalty. Increase retention rates in midst of streaming and social media',
    website: 'http://www.netflix.com',
    pathway: 'Netflix'
  },
  {
    employer: 'Nike',
    problem:
      'Think of game-changing solutions to enhance Nike online shopping experience by creating a virtual try-on feature using cutting-edge tech for easy product visualization. Current solutions lack user engagement',
    website: 'http://www.nike.com',
    pathway: 'Nike'
  },
  {
    employer: 'Peloton Interactive',
    problem:
      'Think outside the box to invent AI-powered virtual coaching for all levels on the Peloton app for exercise performance. Current solutions lack personalization and real-time feedback',
    website: 'http://www.onepeloton.com',
    pathway: 'Peloton Interactive'
  },
  {
    employer: 'Snap Inc.',
    problem:
      'Think outside the box! Enhance learning with a tailored path on Snapchat, suggesting educational content based on interests, skills & individual learning goals. Current solutions lack customization',
    website: 'http://www.snap.com',
    pathway: 'Snap Inc.'
  },
  {
    employer: 'SpaceX',
    problem:
      'Think of creative solutions for sustainable space travel. Showcase potential impact on future of space. Current solutions are limited & costly, need fresh ideas from young minds to advance space exploration',
    website: 'http://www.spacex.com',
    pathway: 'SpaceX'
  },
  {
    employer: 'Square',
    problem:
      'Think of new ways to improve Square’s appointment booking system for sellers’ diversity of services, increasing customer loyalty and engagement across diverse businesses',
    website: 'http://www.squareup.com',
    pathway: 'Square'
  },
  {
    employer: 'Tesla',
    problem:
      'Think creatively to elevate Tesla’s virtual events or livestream content to fans with experts sharing cutting-edge electric vehicle tech & sustainable energy solutions',
    website: 'http://www.tesla.com',
    pathway: 'Tesla'
  },
  {
    employer: 'Turner Construction Company',
    problem:
      'Think of creative ways to partner with schools to incorporate real-world construction projects into coursework, providing valuable hands-on learning opportunities for students. Current solutions lack practical application',
    website: 'http://www.turnerconstruction.com',
    pathway: 'Turner Construction Company'
  },
  {
    employer: 'Walt Disney World',
    problem:
      'Think of interactive virtual simulations to engage Cast Members in training, learning, and adapting to Disney World’s ever-changing experiences',
    website: 'http://www.disneycareers.com',
    pathway: 'Walt Disney World'
  },
  {
    employer: 'Zoom',
    problem:
      'Think of creative solutions to enhance collaboration for Zoom users by integrating AI, real-time editing, document sharing, and task management. Current solutions lack seamless integration',
    website: 'http://www.zoom.us',
    pathway: 'Zoom'
  }
  // Add more problems as needed
];

export default defineComponent({
  name: 'MProblem',
  components: {
    ACombobox: () => import('@/components/atoms/ACombobox.vue')
    // ATextArea: () => import('@/components/atoms/ATextArea.vue')
  },
  setup() {
    const pathways = ref('Any');
    const pathwaysOptions = ref(PATHWAYS);
    const styling = {
      class: 'module-setup__combobox',
      placeholder: 'Choose an employer',
      label: 'Choose an employer',
      outlined: true,
      rounded: true,
      'small-chips': true,
      'hide-details': true
    };
    const isLoading = ref(false);
    const isDisplayProblem = ref(false);
    const problemData = ref();
    const solution = ref();
    const isSavingSolution = ref(false);
    const timerInSeconds = 0;
    const countdown = ref(0); // Start from 0 for counting up
    let interval;
    const isOnboarding = ref(false);
    const isSaved = ref(false);
    const openLogsDialog = ref(false);
    const previousLogs = ref([]);

    const filteredProblems = computed(() => {
      if (pathways.value === 'Any') {
        return PROBLEMS;
      }
      return PROBLEMS.filter(problem => problem.pathway === pathways.value);
    });

    const saveState = () => {
      const state = {
        pathways: pathways.value,
        isDisplayProblem: isDisplayProblem.value,
        problemData: problemData.value,
        countdown: countdown.value
      };
      localStorage.setItem('problemState', JSON.stringify(state));
    };

    const startCountdown = async () => {
      interval = setInterval(async () => {
        countdown.value += 1; // Increment instead of decrement
        saveState(); // Save state on every tick
      }, 1000);
    };

    // Watch for changes in countdown and update the timer value
    const timer = computed(() => {
      const minutes = Math.floor((countdown.value % 108000) / 60);
      const seconds = countdown.value % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    });

    const loadSavedState = () => {
      const savedState = localStorage.getItem('problemState');
      if (savedState) {
        const state = JSON.parse(savedState);
        pathways.value = state.pathways;
        isDisplayProblem.value = state.isDisplayProblem;
        problemData.value = state.problemData;
        countdown.value = state.countdown;
        if (isDisplayProblem.value && countdown.value > 0) {
          startCountdown();
        }
      }
    };

    const generateProblemScope = async () => {
      isLoading.value = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const availableProblems = filteredProblems.value;
        if (availableProblems.length > 0) {
          const randomProblem =
            availableProblems[Math.floor(Math.random() * availableProblems.length)];
          isDisplayProblem.value = true;
          problemData.value = randomProblem;
          countdown.value = timerInSeconds; // Reset countdown when generating a new problem
          saveState(); // Save state after selecting a problem
          await startCountdown();
        } else {
          console.log('No problems available for the selected pathway');
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    const openWebsiteInNewWindow = () => {
      window.open(problemData?.value?.website, '_blank');
    };

    const saveSolution = async (event = null) => {
      clearInterval(interval);
      if (event !== null) isSavingSolution.value = true;
      const timeTaken = countdown.value; // Use countdown value directly
      const payload = {
        employer: problemData?.value?.employer,
        timeSpent: timeTaken === 1 ? `${timeTaken} second` : `${timeTaken} seconds`,
        problem: problemData?.value?.problem,
        solution: solution?.value,
        pathways: pathways?.value
      };

      // Save to local storage
      const savedLogs = JSON.parse(localStorage.getItem('problemLogs') || '[]');
      savedLogs.push(payload);
      localStorage.setItem('problemLogs', JSON.stringify(savedLogs));

      // Reset state
      countdown.value = 0;
      problemData.value = null;
      solution.value = '';
      pathways.value = 'Any';
      isDisplayProblem.value = false;
      previousLogs.value = savedLogs;
      isSaved.value = true;

      // Clear saved state after submitting a solution
      localStorage.removeItem('problemState');

      if (event !== null) isSavingSolution.value = false;
    };

    // Load previous logs from local storage
    const loadPreviousLogs = () => {
      previousLogs.value = JSON.parse(localStorage.getItem('problemLogs') || '[]');
    };

    loadPreviousLogs();

    const chooseAnotherEmployer = () => {
      // Reset state
      countdown.value = 0;
      problemData.value = null;
      solution.value = '';
      pathways.value = 'Any';
      isDisplayProblem.value = false;
      isSaved.value = false;

      // Clear saved state
      localStorage.removeItem('problemState');
    };

    onMounted(() => {
      loadSavedState();
    });

    return {
      pathways,
      pathwaysOptions,
      styling,
      isLoading,
      isDisplayProblem,
      problemData,
      solution,
      timer,
      isSavingSolution,
      isOnboarding,
      previousLogs,
      openLogsDialog,
      isSaved,
      generateProblemScope,
      openWebsiteInNewWindow,
      saveSolution,
      chooseAnotherEmployer
    };
  }
});
