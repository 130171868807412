import activity from './m-problem.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Problem',
  icon: 'mdi-fire',
  description: 'Choose your employer and get a problem to solve!',
  status: true,
  setupRequired: false,
  styling: {
    borderColor: '#3c9dcd'
  },
  fields: [
    {
      roles: ['employer'],
      type: 'noSetup',
      title: 'No setup necessary',
      noSetup: true,
      position: 0
    },
    {
      roles: ['participant'],
      name: 'm-problem',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {
        boards: []
      },
      value: {}
    },
    {
      roles: ['participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 2,
      preset: {},
      setup: {},
      value: {}
    }
  ],
  presetFields: [
    {
      roles: ['employer'],
      type: 'noTweaks',
      title: 'No tweaking necessary',
      noSetup: true,
      position: 0
    }
  ]
};
